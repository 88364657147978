import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { Route } from '../path';
import { Loadable } from '../utils';

const EnglishTestReport = Loadable(
  lazy(() => import('pages/english-test-report/english-test-report')),
);
const ReportDetail = Loadable(
  lazy(() => import('pages/english-test-report/report-detail')),
);

const generateEnglishTestReportRoutes = () => {
  return {
    path: Route.englishtestreport,
    element: <EnglishTestReport />,
    children: (() => {
      const firstFolder: RouteObject[] = [];

      firstFolder.push({
        path: Route.id,
        element: <ReportDetail />,
        index: true,
      });
      return firstFolder;
    })(),
  };
};

export { generateEnglishTestReportRoutes };
